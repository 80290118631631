<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/pages/contact/bg.jpg">
            <p class="career-header" style="text-align: center; font-size: 3rem; color: var(--default-text-color);">
                {{ $t('contact.header') }}</p>
        </div>
        <div style="text-align: center; margin: auto; margin-top: 85vh; width: min-content ;">
            <p class="energy-block-header" style="font-size: 2rem; ">{{ $t('contact.title') }}</p>
            <p>{{ $t('contact.detail') }}</p>
            <div class="contact-cards-holder">
                <ContactCard title="Merkez Ofis" icon="logo-icon.png" phone="0486 221 10 06" mail="info@dgsnas.com"
                    adress="Bahçelievler Mah. Mardin Bulvarı, No:322, Merkez/Şırnak" />
                <ContactCard title="Global Şırnak Shell" icon="logo-icon.png" phone="0486 221 10 06"
                    mail="6593sirnakmerkez@stasbayi.com"
                    adress="Bahçelievler Mah. Mardin Bulvarı, No:322, Merkez/Şırnak" />
                <ContactCard title="Doğusan Milas Shell" icon="logo-icon.png" phone="0252 512 1505"
                    mail="6205milasgirisi@stasbayi.com"
                    adress="Aydınlıkevler Mah. 19 Mayıs Bulvarı, No:137, Milas/Muğla" />
                <ContactCard title="Maviliman Milas Shell" icon="logo-icon.png" phone="0252 513 0987"
                    mail="6463ahmetyesevi@stasbayi.com" adress="Cumhuriyet Mah. Yatağan Bulvarı, No:23, Milas/Muğla" />
                <ContactCard title="Saltan Petrol Şırnak" icon="logo-icon.png" phone="0486 216 2273"
                    mail="saltanpetrolculuk@gmail.com" adress="Yeni Mah. Hakkari Bulvarı, No:26, Merkez/Şırnak" />
                <ContactCard title="Şırnak Zırhlı Servis" icon="logo-icon.png" phone="0486 221 10 06"
                    mail="info@dgsnas.com" adress="Bahçelievler Mah. Mardin Bulvarı, No:324, Merkez/Şırnak" />
                <ContactCard title="Diyarbakır Zırhlı Servis" icon="logo-icon.png" phone="0486 221 10 06"
                    mail="info@dgsnas.com"
                    adress="Seyrantepe Sanayi Mah. 1. Sanayi Sitesi, 28. Sokak, No:4 Yenişehir/Diyarbakır" />
            </div>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import ContactCard from '@/components/contact_page/ContactCard.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'CareerPage',
    components: {
        TopBar,
        FooterComponent,
        ContactCard
    }
}
</script>

<style>
.contact-cards-holder {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    padding: 30px;
    width: calc(var(--max-width) - 100px);
    min-width: 415px;
    justify-items: center;
    align-items: center;
}


@media (max-width: 1300px) {
    .contact-cards-holder {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 900px) {
    .contact-cards-holder {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        min-width: 250px;
    }
}

@import '@/pages/career/CareerPage.css';
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>