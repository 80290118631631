<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/pages/about/bg.jpg">
            <p class="career-header">{{ $t('aboutPage.quote') }}</p>
        </div>
        <div class="energy-content">
            <div class="energy-block-details">
                <p class="energy-block-header">{{ $t('aboutPage.title') }}</p>
                <p v-html="$t('aboutPage.description')"></p>
            </div>
        </div>
        <br>
        <br>
        <br>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'CareerPage',
    components: {
        TopBar,
        FooterComponent
    }
}
</script>

<style>
@import '@/pages/career/CareerPage.css';
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>