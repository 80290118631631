<template>
    <div id="header-branding">
        <div class="logo" @click="goHome()">
            <img src="@/assets/logo/logo-icon-white.png" alt="Doğusan Grup Logo" class="header-logo"
                id="header-logo-white" v-if="!headerIsDefaultColor" />
            <img src="@/assets/logo/logo-icon.png" alt="Doğusan Grup Logo" class="header-logo" id="header-logo-color"
                v-if="headerIsDefaultColor" />
            <span id="header-logo-text" v-if="displayText" :style="headerLogoTextStyle">{{this.$toLocaleUppercase(this.$t('general.logo'))}}</span>
        </div>
        <div class="spacer" :class="{ 'default-color': headerIsDefaultColor, 'mobile': !displayText }"></div>
        <div class="lang-switch">
            <div id="lang-switch-header">
                <div class="icon" :class="{ 'default-color': headerIsDefaultColor }"></div>
                <p :class="{ 'default-color': headerIsDefaultColor }">{{ currentLanguageLabel }}</p>
            </div>
            <div class="lang-switch-content">
                <button @click="switchLanguage('tr')" class="hover-btn"><img src="@/assets/images/lang/turkish.png"
                        alt="TR">Türkçe</button>
                <button @click="switchLanguage('en')" class="hover-btn"><img src="@/assets/images/lang/english.png"
                        alt="EN">English</button>
                <button @click="switchLanguage('de')" class="hover-btn"><img src="@/assets/images/lang/german.png"
                        alt="DE">Deutsch</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderBranding',
    props: {
        headerIsDefaultColor: {
            type: Boolean
        },
        displayText: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        headerLogoTextStyle() {
            return this.headerIsDefaultColor
                ? { color: "var(--brand-color)", textShadow: "none" }
                : { color: "white", textShadow: "var(--text-shadow-2)" };
        },
        currentLanguageLabel() {
            return this.$i18n.locale.toUpperCase();
        }
    },
    methods: {
        switchLanguage(lang) {
            // this.$i18n.locale = lang;
            localStorage.setItem('userLanguage', lang);
            location.reload(); // Refresh the page to apply the new language globally
        },
        goHome() {
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>
#header-branding {
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header-logo {
    height: 40px;
    margin-right: 15px;
}

#header-logo-text {
    font-family: 'Atc Timberline', "Montserrat", Arial, sans-serif;
    letter-spacing: -1px;
    font-size: 1.25rem;
    font-weight: 600;
    color: #fff;
    text-shadow: var(--shadow-2);
    white-space: nowrap;
}

#header-branding .spacer {
    background-color: rgba(255, 255, 255, 0.6);
    margin: 0 15px 0 20px;
    height: calc(var(--header-height) / 2);
    width: 1px;
}

#header-branding .spacer.mobile {
    margin: 0;
    margin-left: 2px;
}

#header-branding .spacer.default-color {
    background-color: rgba(0, 0, 0, 0.3);
}

#lang-switch-header {
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.lang-switch:active #lang-switch-header p,
.lang-switch:hover #lang-switch-header p {
    color: var(--default-text-color);
}

.lang-switch:active #lang-switch-header .icon,
.lang-switch:hover #lang-switch-header .icon {
    background-color: var(--default-text-color);
}

#lang-switch-header p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8rem;
    font-weight: 600;
    text-decoration: none;
    margin-left: 10px;
    z-index: 9;
}

#lang-switch-header p.default-color {
    color: rgba(0, 0, 0, 0.3);
}

#lang-switch-header .icon {
    width: 20px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.6);
    mask: url("@/assets/icons/lang-switch.svg") no-repeat center;
    mask-size: contain;
    -webkit-mask: url("@/assets/icons/lang-switch.svg") no-repeat center;
    -webkit-mask-size: contain;
    z-index: 9;
}

#lang-switch-header .icon.default-color {
    background-color: rgba(0, 0, 0, 0.3);
}

.lang-switch-content {
    position: absolute;
    visibility: hidden;
    padding: 40px 40px 15px 15px;
    margin-top: -30px;
    background-color: rgb(255, 255, 255);
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.lang-switch:active .lang-switch-content,
.lang-switch:hover .lang-switch-content {
    visibility: visible;
    opacity: 1;
}

.lang-switch-content .hover-btn {
    display: block;
    padding: 0;
    margin: 8px 0;
    font-size: 1rem;
    font-weight: 400;
    color: var(--default-text-color);
    text-align: start;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.lang-switch-content .hover-btn img {
    height: 12px;
    margin-right: 7px;
}
</style>