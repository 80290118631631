<template>
    <div class="contact-card">
        <div class="icon">
            <img :src="resolvedIcon" alt="icon" />
            <h2>{{ title }}</h2>
        </div>
        <div class="content">
            <div class="block">
                <span class="phone-icon"></span>
                <p>{{ phone }}</p>
            </div>
            <div class="block" v-if="gsm">
                <span class="gsm-icon"></span>
                <p>{{ gsm }}</p>
            </div>
            <div class="block">
                <span class="mail-icon"></span>
                <p><a :href="`mailto:${mail}`">{{ mail }}</a></p>
            </div>
            <div class="block">
                <span class="adress-icon"></span>
                <p>{{ adress }}</p>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "ContactCard",
    props: {
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        phone: {
            type: String,
            required: true
        },
        gsm: {
            type: String
        },
        mail: {
            type: String,
            required: true
        },
        adress: {
            type: String,
            required: true
        }
    },
    computed: {
        resolvedIcon() {
            // Use require to resolve the icon path statically
            try {
                return require(`@/assets/icons/${this.icon}`);
            } catch (error) {
                console.error("Icon not found:", error);
                return ""; // Return a fallback or empty string if the icon is not found
            }
        }
    }
};
</script>

<style scoped>
.contact-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    text-align: start;
    min-height: 170px;
    width: 370px;
    transition: 0.2s
}

.contact-card:hover {
    box-shadow: 0px 4px 10px rgba(46, 0, 139, 0.5);
}

.contact-card .icon {
    display: flex;
    align-items: center;
}

.contact-card .icon img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.contact-card .block {
    display: flex;
    align-items: center;
}

.content {
    padding: 5px 15px;
}

.content h2 {
    color: #0056b3;
    margin: 0;
}

.content p {
    margin: 5px 0;
    color: #333;
    display: flex;
    align-items: center;
    max-width: 270px;
}

.content a {
    color: var(--default-text-color);
}

.content a:hover {
    text-decoration: underline;
}

.phone-icon,
.gsm-icon,
.mail-icon,
.adress-icon {
    width: 16px !important;
    height: 16px !important;
    margin-right: 10px;
    background-size: contain;
    display: inline-block;
}

/* İkon görsellerini arka plan olarak ekleyin */
.phone-icon {
    background-image: url('@/assets/icons/phone-call.svg');
    background-repeat: no-repeat;
}

.gsm-icon {
    background-image: url('@/assets/icons/phone.svg');
    background-repeat: no-repeat;
}

.mail-icon {
    background-image: url('@/assets/icons/mail.svg');
    background-repeat: no-repeat;
}

.adress-icon {
    background-image: url('@/assets/icons/adress.svg');
    background-repeat: no-repeat;
}

@media (max-width: 400px) {
    .contact-card {
        width: 320px;
    }
}
</style>
