<template>
  <div class="wrapper">
    <div class="main">
      <TopBar />
      <BackgroundSlider />
      <div class="bottom-content">
        <div class="contact-btns">
          <div class="contact-box email-box">
            <a href="mailto:info@dgsnas.com">
              <div class="icon-box">
                <div class="icon"></div>
              </div>
              info@dgsnas.com
            </a>
          </div>
          <div class="contact-box phone-box">
            <a href="tel:+904862211006">
              <div class="icon-box">
                <div class="icon"></div>
              </div>
              +90 486 221 1006
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="about-us">
      <p id="animated-text" class="header">
        <span>{{ $t('home.aboutHeader') }}</span>
      </p>
      <p class="explanation" v-html="$t('home.aboutExplanation')"></p>
    </div>
    <div class="companies-content">
      <div class="background-color"></div>
      <div class="upper-pnl">
        <div class="companies-container defense">
          <p class="header-txt">{{ $toLocaleUppercase($t('general.defenseIndustry')) }}</p>
          <p class="lower-txt">{{ $t('general.defenseIndustryDetail') }}</p>
          <router-link to="/industries/defense" class="company-img">
            <div class="hover-effect"></div>
            <img src="@/assets/images/defense.jpeg" alt="Defense">
            <div class="img-content default-content">
              <div class="icon-holder icon-holder-default">
                <div class="icon"></div>
              </div>
            </div>
            <div class="img-content hover-content">
              <p>{{ $t('general.more') }}</p>
              <div class="icon-holder icon-holder-hover">
                <div class="icon icon-hover"></div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="companies-container marine">
          <p class="header-txt">{{ $toLocaleUppercase($t('general.marineIndustry')) }}</p>
          <p class="lower-txt">{{ $t('general.marineIndustryDetail') }}</p>
          <router-link to="/industries/marine" class="company-img">
            <div class="hover-effect"></div>
            <img src="@/assets/images/marine.jpg" alt="Marine">
            <div class="img-content default-content">
              <div class="icon-holder icon-holder-default">
                <div class="icon"></div>
              </div>
            </div>
            <div class="img-content hover-content">
              <p id="energy-segment">{{ $t('general.more') }}</p>
              <div class="icon-holder icon-holder-hover">
                <div class="icon icon-hover"></div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="lower-pnl">
        <p class="header-txt">{{ $toLocaleUppercase($t('general.energyIndustry')) }}</p>
        <p class="lower-txt">{{ $t('general.energyIndustryDetail') }}</p>
        <EnergyContainer />
      </div>
    </div>
    <div class="message">
      <img src="@/assets/images/ceo.png" alt="Image of Founder">
      <div class="pnl-text">
        <div class="p-message" v-html="$t('home.ceoMessage')"></div>
        <p class="p-header">
          <span class="founder">Necip Saltan</span>
          {{ $t('home.ceo') }}
        </p>
      </div>
    </div>
    <div class="contact">
      <div class="line"></div>
      <div class="contact-pnl-holder">
        <div class="contact-pnl work-with-us">
          <p>{{ $t('home.joinUsDetail') }}</p>
          <LinkButton :title="$t('home.joinUs')" linkTo="/career" />
        </div>
        <div class="contact-pnl organization-scheme">
          <p>{{ $t('home.organizationChartDetail') }}</p>
          <LinkButton :title="$t('home.organizationChart')" linkTo="/organization-scheme" />
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import BackgroundSlider from '@/components/home_page/BackgroundSlider.vue';
import EnergyContainer from '@/components/home_page/EnergyContainer.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import LinkButton from '@/components/LinkButton.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
  name: "HomePage",
  components: {
    TopBar,
    FooterComponent,
    LinkButton,
    BackgroundSlider,
    EnergyContainer,
  },
  metaInfo: {
    title: 'Anasayfa | Doğusan Grup',
    meta: [
      {
        name: 'description',
        content: 'Doğusan Grup, enerji, otomotiv, savunma sanayi ve denizcilik sektörlerinde uzmanlaşmış, güvenilir ve yenilikçi bir hizmet sağlayıcıdır'
      },
      {
        name: 'keywords',
        content: 'Doğusan Grup, doğusan, zırhlı araç, yedek parça, akaryakıt istasyonu, denizcilik, benzin istasyonu, shell, opet'
      },
      {
        property: 'og:title',
        content: 'Anasayfa | Doğusan Grup'
      },
      {
        property: 'og:description',
        content: 'Doğusan Grup, enerji, otomotiv, savunma sanayi ve denizcilik sektörlerinde uzmanlaşmış, güvenilir ve yenilikçi bir hizmet sağlayıcıdır'
      },
      {
        property: 'og:image',
        content: 'https://dgsnas.com/img/logo-full.4d92365c.png'
      },
      {
        property: 'og:url',
        content: 'https://dgsnas.com/'
      }
    ]
  }
};
</script>

<style>
@import "@/pages/HomePage.css";
</style>
