<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/energy-container/maviliman-enerji.jpeg" alt="image of Maviliman Energy">
        </div>
        <div class="energy-content">
            <div class="energy-details">
                <h1>{{ $t('mavilimanMilasShell.title') }}</h1>
                <p v-html="$t('mavilimanMilasShell.introduction')"></p>
            </div>
            <div class="energy-block energy-left-block">
                <img src="@/assets/images/pages/energy/MavilimanMilasShell/market.jpeg">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('mavilimanMilasShell.shellSelectTitle') }}</p>
                    <p v-html="$t('mavilimanMilasShell.shellSelectDescription')"></p>
                </div>
            </div>
            <div class="energy-block energy-right-block">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('mavilimanMilasShell.safetyStandardsTitle') }}</p>
                    <p v-html="$t('mavilimanMilasShell.safetyStandardsDescription')"></p>
                    <p class="energy-block-header energy-block-header-second">{{ $t('mavilimanMilasShell.environmentSustainabilityTitle') }}</p>
                    <p v-html="$t('mavilimanMilasShell.environmentSustainabilityDescription')"></p>
                </div>
                <img src="@/assets/images/pages/energy/MavilimanMilasShell/disari.jpeg">
            </div>
            <div class="energy-block-details">
                <p class="energy-block-header energy-block-header-second">{{ $t('mavilimanMilasShell.tirePressureTitle') }}</p>
                <p v-html="$t('mavilimanMilasShell.tirePressureDescription')"></p>
            </div>
            <div class="energy-block-details">
                <p class="energy-block-header energy-block-header-second">{{ $t('mavilimanMilasShell.futureGoalsTitle') }}</p>
                <p v-html="$t('mavilimanMilasShell.futureGoalsDescription')"></p>
            </div>
            <div class="energy-block energy-left-block">
                <iframe class="map-iframe"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.28078049893!2d27.793923995738158!3d37.28795634321715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14befbbeba6d91d1%3A0x4b3e408f5a229166!2sShell!5e0!3m2!1str!2str!4v1730703880836!5m2!1str!2str"
                    style="border:0;" allowfullscreen="false" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('mavilimanMilasShell.locationTitle') }}</p>
                    <p v-html="$t('mavilimanMilasShell.locationDescription')"></p>
                    <p class="energy-block-header energy-block-header-second">{{ $t('mavilimanMilasShell.contactInfoTitle') }}</p>
                    <p>Cumhuriyet Mah. Yatağan Bulvarı, No:23, Milas/Muğla</p>
                    <br>
                    <div class="energy-icon-holder">
                        <div class="phone-icon"></div>
                        <p>0252 513 0987</p>
                    </div>
                    <div class="energy-icon-holder">
                        <div class="email-icon"></div>
                        <p>6463ahmetyesevi@stasbayi.com</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'MavilimanMilasShell',
    components: {
        TopBar,
        FooterComponent
    }
}
</script>

<style>
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>
