<template>
    <div id="content-btn" @mouseover="onPanelOpen" @mouseleave="onPanelClose">
        <div class="content-btn-header">
            <a href="#" :class="{ 'default-color': headerIsDefaultColor, 'visible': panelVisibility }">
                {{ title }}
                <div class="icon"></div>
            </a>
        </div>
        <div id="content-btn-content" :class="{ 'visible': panelVisibility }">
            <div class="content-btn-content-holder">
                <div class="text-content">
                    <p class="fore-text">{{ title }}</p>
                    <p class="main-text">{{ slogan }}</p>
                </div>
                <div class="main-content">
                    <slot></slot>
                </div>
            </div>
            <div class="bottom-line"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderButtonWithContent',
    props: {
        title: {
            type: String,
            required: true
        },
        slogan: {
            type: String
        }
    },
    inject: ['isServicesPanelOpen', 'isCorporatePanelOpen', 'scrollPosition', 'headerBeginingSpacing', 'headerIsDefaultColor'],
    data() {
        return {
            panelHideTimeout: null,
        }
    },
    computed: {
        panelVisibility() {
            if (this.title === this.$t('topbar.sectors')) {
                return this.isServicesPanelOpen;
            } else if (this.title === this.$t('topbar.corporate')) {
                return this.isCorporatePanelOpen;
            } else {
                console.log('You either forget to match title or provide new variable!');
                return false;
            }
        },
    },
    methods: {
        onPanelOpen() {
            if (this.panelHideTimeout) clearTimeout(this.panelHideTimeout);

            if (this.title === this.$t('topbar.sectors')) {
                this.isServicesPanelOpen = true;
                this.isCorporatePanelOpen = false;
            } else if (this.title === this.$t('topbar.corporate')) {
                this.isCorporatePanelOpen = true;
                this.isServicesPanelOpen = false;
            } else {
                console.log('You either forget to match title or provide new variable!');
            }

            this.headerIsDefaultColor = true;
        },
        onPanelClose() {
            this.panelHideTimeout = setTimeout(() => {
                if (this.title === this.$t('topbar.sectors')) {
                    this.isServicesPanelOpen = false;
                    if (!this.isCorporatePanelOpen && this.scrollPosition < this.headerBeginingSpacing) {
                        this.headerIsDefaultColor = false;
                    }
                } else if (this.title === this.$t('topbar.corporate')) {
                    this.isCorporatePanelOpen = false;
                    if (!this.isServicesPanelOpen && this.scrollPosition < this.headerBeginingSpacing) {
                        this.headerIsDefaultColor = false;
                    }
                } else {
                    console.log('You either forget to match title or provide new variable!');
                }
            }, 500);
        },
    },
}
</script>

<style>
@import '@/components/topbar/HeaderButtonWithContent.css';
</style>
