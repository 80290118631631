<template>
    <div>
        <a v-if="isLinkExternal" :href="linkTo" class="link-btn">
            {{ title }} <div class="icon"></div>
        </a>
        <div v-else-if="isLinkScrolling" class="link-btn" @click.prevent="scrollToSegment">
            {{ title }} <div class="icon"></div>
        </div>
        <router-link v-else :to="linkTo" class="link-btn">
            {{ title }} <div class="icon"></div>
        </router-link>
    </div>
</template>
<script>
export default {
    name: 'LinkButton',
    props: {
        title: {
            type: String,
            required: true
        },
        linkTo: {
            type: String,
            required: true
        },
        isLinkExternal: {
            type: Boolean,
            default: false
        },
        isLinkScrolling: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        scrollToSegment() {
            this.$nextTick(() => {
                const segment = document.getElementById(this.linkTo);
                if (segment) {
                    segment.scrollIntoView({ behavior: 'smooth' });
                }
            });
        }
    }
}
</script>

<style>
.link-btn {
    display: flex;
    align-items: center;
    width: min-content;
    white-space: nowrap;
    color: white;
    cursor: pointer;
    text-decoration: none;
    padding: 12px 30px;
    font-weight: 500;
    background-color: var(--brand-color);
}

.link-btn .icon {
    height: 20px;
    width: 0px;
    margin: auto;
    margin-left: 0px;
    background-color: #fff;
    mask: url("@/assets/icons/arrow-small-right.svg") no-repeat center;
    mask-size: contain;
    -webkit-mask: url("@/assets/icons/arrow-small-right.svg") no-repeat center;
    -webkit-mask-size: contain;
    transform: rotate(-45deg);
    transition: width 0.2s, margin-left 0.2s;
}

.link-btn:hover .icon {
    width: 20px;
    margin-left: 10px;
}
</style>