<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/pages/social-responsibility/bg.jpg">
            <p class="career-header">{{ $t('socialResponsibilityPage.quote') }}</p>
        </div>
        <div class="energy-content">
            <div class="energy-block-details">
                <p class="energy-block-header">{{ $t('socialResponsibilityPage.principlesTitle') }}</p>
                <p v-html="$t('socialResponsibilityPage.principlesDescription')"></p>
                <p class="energy-block-header energy-block-header-second">{{ $t('socialResponsibilityPage.futureProjectsTitle') }}</p>
                <p v-html="$t('socialResponsibilityPage.futureProjectsDescription')"></p>
                <p class="energy-block-header energy-block-header-second">{{ $t('socialResponsibilityPage.supportedThemesTitle') }}</p>
                <p v-html="$t('socialResponsibilityPage.supportedThemesDescription')"></p>
                <p class="energy-block-header energy-block-header-second">{{ $t('socialResponsibilityPage.volunteeringTitle') }}</p>
                <p v-html="$t('socialResponsibilityPage.volunteeringDescription')"></p>
                <p class="energy-block-header energy-block-header-second">{{ $t('socialResponsibilityPage.visionTitle') }}</p>
                <p v-html="$t('socialResponsibilityPage.visionDescription')"></p>
            </div>
        </div>
        <br>
        <br>
        <br>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'SocialResponsibilityPage',
    components: {
        TopBar,
        FooterComponent
    }
}
</script>

<style>
@import '@/pages/career/CareerPage.css';
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>
