<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/energy-container/global-petrol.jpeg" alt="image of global petrol">
        </div>
        <div class="energy-content">
            <div class="energy-details">
                <h1>{{ $t('globalSirnakShell.title') }}</h1>
                <p v-html="$t('globalSirnakShell.introduction')"></p>
            </div>
            <div class="energy-block energy-left-block">
                <img src="@/assets/images/pages/energy/GlobalSirnakShell/market.jpeg">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('globalSirnakShell.shellSelectTitle') }}</p>
                    <p v-html="$t('globalSirnakShell.shellSelectDescription')"></p>
                </div>
            </div>
            <div class="energy-block energy-right-block">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('globalSirnakShell.deli2goCafeTitle') }}</p>
                    <p v-html="$t('globalSirnakShell.deli2goCafeDescription')"></p>
                    <p class="energy-block-header energy-block-header-second">{{ $t('globalSirnakShell.tirePressureTitle') }}</p>
                    <p v-html="$t('globalSirnakShell.tirePressureDescription')"></p>
                    <p class="energy-block-header energy-block-header-second">{{ $t('globalSirnakShell.carWashTitle') }}</p>
                    <p v-html="$t('globalSirnakShell.carWashDescription')"></p>
                </div>
                <img src="@/assets/images/pages/energy/GlobalSirnakShell/cafe-alani.jpeg">
            </div>
            <div class="energy-block-details">
                <p class="energy-block-header" style="margin-top: 25px;">{{ $t('globalSirnakShell.evChargingTitle') }}</p>
                <p v-html="$t('globalSirnakShell.evChargingDescription')"></p>
            </div>
            <div class="energy-block-details">
                <p class="energy-block-header energy-block-header-second">{{ $t('globalSirnakShell.safetyStandardsTitle') }}</p>
                <p v-html="$t('globalSirnakShell.safetyStandardsDescription')"></p>
            </div>
            <div class="energy-block-details">
                <p class="energy-block-header energy-block-header-second">{{ $t('globalSirnakShell.environmentSustainabilityTitle') }}</p>
                <p v-html="$t('globalSirnakShell.environmentSustainabilityDescription')"></p>
            </div>
            <div class="energy-block-details">
                <p class="energy-block-header energy-block-header-second">{{ $t('globalSirnakShell.futureGoalsTitle') }}</p>
                <p v-html="$t('globalSirnakShell.futureGoalsDescription')"></p>
            </div>
            <div class="energy-block energy-left-block">
                <iframe class="map-iframe"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1292.9107061220252!2d42.41877148801517!3d37.503578897848485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4009573984a601ed%3A0x8009e0ceebaae1f2!2sShell!5e0!3m2!1str!2str!4v1730657720303!5m2!1str!2str"
                    style="border:0;" allowfullscreen="false" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('globalSirnakShell.locationTitle') }}</p>
                    <p v-html="$t('globalSirnakShell.locationDescription')"></p>
                    <p class="energy-block-header energy-block-header-second">{{ $t('globalSirnakShell.contactInfoTitle') }}</p>
                    <p>Bahçelievler Mah. Mardin Bulvarı, No:322, Merkez/Şırnak</p>
                    <br>
                    <div class="energy-icon-holder">
                        <div class="phone-icon"></div>
                        <p>0486 221 10 06</p>
                    </div>
                    <div class="energy-icon-holder">
                        <div class="email-icon"></div>
                        <p>6593sirnakmerkez@stasbayi.com</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'GlobalSirnakShell',
    components: {
        TopBar,
        FooterComponent
    },
    metaInfo: {
        title: 'Enerji | Doğusan Grup',
        meta: [
            {
                name: 'description',
                content: 'Kalite odaklı akaryakıt, lpg ve elektrikli şarj istasyonlarımızda, güvenilir ve yüksek standarttaki hizmetlerimizi deneyimleyin.'
            },
            {
                name: 'keywords',
                content: 'Opet, Shell, Benzin istasyonu, Akaryakıt, Otogaz, LPG, Elektrikli şarj istasyonu, Doğusan, Benzinlik, Petrol ofisi'
            },
            {
                property: 'og:title',
                content: 'Enerji | Doğusan Grup'
            },
            {
                property: 'og:description',
                content: 'Kalite odaklı akaryakıt, lpg ve elektrikli şarj istasyonlarımızda, güvenilir ve yüksek standarttaki hizmetlerimizi deneyimleyin.'
            },
            {
                property: 'og:image',
                content: 'https://dgsnas.com/img/logo-full.4d92365c.png'
            },
            {
                property: 'og:url',
                content: 'https://dgsnas.com/industries/energy/global-sirnak-shell'
            }
        ]
    }
}
</script>

<style>
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>