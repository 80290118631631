<template>
    <div class="energy-container" :style="{ backgroundImage: `url(${hoveredImage})` }">
        <div v-for="(data, index) in companyObjects" :key="index" class="energy-object"
            @mouseover="setHoveredData(data)" @mouseleave="clearHoveredData">
            <div class="energy-object-items">
                <p class="title">{{ data.title }}</p>
                <div v-if="hoveredTitle !== data.title" class="details">
                    <div class="icon-holder icon-holder-default">
                        <div class="icon"></div>
                    </div>
                </div>
                <div v-if="hoveredTitle === data.title" class="details">
                    <p class="detail-txt">{{ data.detail }}</p>
                    <router-link :to='data.link' class="go-btn">
                        <p>{{ $t('general.more') }}</p>
                        <div class="icon-holder icon-holder-hover">
                            <div class="icon icon-hover"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EnergyContainer',
    data() {
        return {
            hoveredTitle: '',
            hoveredImage: '',
            companyObjects: [
                {
                    title: "Global Petrol",
                    detail: this.$t('home.globalPetrolDetail'),
                    link: "/industries/energy/global-sirnak-shell",
                    image: require('@/assets/images/energy-container/global-petrol.jpeg')
                },
                {
                    title: "Doğusan Petrol",
                    detail:  this.$t('home.dogusanPetrolDetail'),
                    link: "/industries/energy/dogusan-milas-shell",
                    image: require('@/assets/images/energy-container/dogusan-petrol.jpeg')
                },
                {
                    title: "Maviliman Enerji",
                    detail:  this.$t('home.mavilimanPetrolDetail'),
                    link: "/industries/energy/maviliman-milas-shell",
                    image: require('@/assets/images/energy-container/maviliman-enerji.jpeg')
                },
                {
                    title: "Saltan Petrol",
                    detail:  this.$t('home.saltanPetrolDetail'),
                    link: "/industries/energy/saltan-petrol-sirnak-opet",
                    image: require('@/assets/images/energy-container/saltan-petrol.jpeg')
                }
            ]
        };
    },
    methods: {
        setHoveredData(data) {
            this.hoveredTitle = data.title;
            this.hoveredImage = data.image;
        },
        clearHoveredData() {
            this.hoveredTitle = '';
            this.hoveredImage = require('@/assets/images/energy-container/global-petrol.jpeg'); // Varsayılan arka plan resmi
        }
    },
    mounted() {
        this.hoveredImage = require('@/assets/images/energy-container/global-petrol.jpeg'); // Başlangıç arka plan resmi
    }
}
</script>

<style scoped>
@import '@/components/home_page/EnergyContainer.css';
</style>