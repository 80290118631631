<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.handleResize();

    // Diğer gerekli event listener'ları ekleyin
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      const root = document.documentElement;
      const targetMaxInnerWidth = window.innerWidth > 1400 ? 1400 : window.innerWidth;
      const targetMaxOuterWidth = window.outerWidth > 1400 ? 1400 : window.outerWidth;
      const targetMaxWidth = Math.min(targetMaxInnerWidth, targetMaxOuterWidth)

      root.style.setProperty("--max-width", targetMaxWidth + 'px');
    },
  }
};
</script>