<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/energy-container/saltan-petrol.jpeg" alt="image of saltan petrol">
        </div>
        <div class="energy-content">
            <div class="energy-details">
                <h1>{{ $t('saltanPetrolOpet.title') }}</h1>
                <p v-html="$t('saltanPetrolOpet.introduction')"></p>
            </div>
            <div class="energy-block energy-left-block">
                <img src="@/assets/images/pages/energy/SaltanPetrol/market.jpeg">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('saltanPetrolOpet.servicesTitle') }}</p>
                    <p v-html="$t('saltanPetrolOpet.servicesDescription')"></p>
                </div>
            </div>
            <div class="energy-block energy-right-block">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('saltanPetrolOpet.customerServiceTitle') }}</p>
                    <p v-html="$t('saltanPetrolOpet.customerServiceDescription')"></p>
                </div>
                <img src="@/assets/images/pages/energy/SaltanPetrol/disari.jpeg">
            </div>
            <div class="energy-block energy-left-block">
                <iframe class="map-iframe saltan-map-iframe"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2705.2044214602092!2d42.44934233527976!3d37.510922835978405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4009552e07d9fa67%3A0x421e1fae393942a7!2zT3BldCBTYWx0YW4gUGV0cm9sY8O8bMO8ayDFnsSxcm5haw!5e1!3m2!1str!2str!4v1730704432391!5m2!1str!2str"
                    style="border:0;" allowfullscreen="false" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('saltanPetrolOpet.locationTitle') }}</p>
                    <p v-html="$t('saltanPetrolOpet.locationDescription')"></p>
                    <p class="energy-block-header energy-block-header-second">{{ $t('saltanPetrolOpet.contactInfoTitle') }}</p>
                    <p>Yeni Mah. Hakkari Bulvarı, No:26, Merkez/Şırnak</p>
                    <br>
                    <div class="energy-icon-holder">
                        <div class="phone-icon"></div>
                        <p>0486 216 2273</p>
                    </div>
                    <div class="energy-icon-holder">
                        <div class="email-icon"></div>
                        <p>saltanpetrolculuk@gmail.com</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'SaltanPetrolSirnakOpet',
    components: {
        TopBar,
        FooterComponent
    }
}
</script>

<style>
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>
