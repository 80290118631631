<template>
    <div class="mtopbar" :class="{ active: isActive }">
        <div class="mtopbar-background"></div>
        <div class="mtopbar-branding">
            <HeaderBranding :headerIsDefaultColor="!isActive" :displayText="windowWidth >= 550" />
        </div>
        <div class="mtopbar-btn" @click="toggleMenu">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
        </div>
    </div>
    <div>
        <div class="mtopbar-content-bg" :class="{ active: isActive }"></div>
        <div class="mtopbar-content" :class="{ active: isActive }">
            <nav class="mtopbar-nav">
                <ul>
                    <li>
                        <HeaderButtonWithContentMobile idName="industries" :title="$t('topbar.sectors')">
                            <div>
                                <router-link to="/industries/defense" class="header-text-holder">
                                    <p class="header-text">{{ $t('general.defenseIndustry') }}</p>
                                    <p class="fore-text">{{ $t('general.defenseIndustryDetail') }}</p>
                                </router-link>
                            </div>
                            <div>
                                <router-link to="/industries/marine" class="header-text-holder">
                                    <p class="header-text">{{ $t('general.marineIndustry') }}</p>
                                    <p class="fore-text">{{ $t('general.marineIndustryDetail') }}</p>
                                </router-link>
                            </div>
                            <div>
                                <p class="header-text">{{ $t('general.energyIndustry') }}</p>
                                <router-link to="/industries/energy/global-sirnak-shell" class="fore-text">Global Şırnak Shell</router-link>
                                <router-link to="/industries/energy/dogusan-milas-shell" class="fore-text">Doğusan Milas Shell</router-link>
                                <router-link to="/industries/energy/maviliman-milas-shell" class="fore-text">Maviliman Milas Shell</router-link>
                                <router-link to="/industries/energy/saltan-petrol-sirnak-opet" class="fore-text">Saltan Petrol Şırnak Opet</router-link>
                            </div>
                        </HeaderButtonWithContentMobile>
                    </li>
                    <li>
                        <HeaderButtonWithContentMobile idName="corporate" :title="$t('topbar.corporate')">
                            <div>
                                <router-link to="/about" class="header-text-holder">
                                    <p class="header-text">{{ $t('topbar.about') }}</p>
                                    <p class="fore-text">{{ $t('topbar.aboutDetail') }}</p>
                                </router-link>
                            </div>
                            <div>
                                <router-link to="/founder" class="header-text-holder">
                                    <p class="header-text">{{ $t('topbar.founder') }}</p>
                                    <p class="fore-text">{{ $t('topbar.founderDetail') }}</p>
                                </router-link>
                            </div>
                            <div>
                                <router-link to="/message-from-manager" class="header-text-holder">
                                    <p class="header-text">{{ $t('topbar.fromChairperson') }}</p>
                                    <p class="fore-text">{{ $t('topbar.fromChairpersonDetail') }}</p>
                                </router-link>
                            </div>
                            <div>
                                <router-link to="/organization-scheme" class="header-text-holder">
                                    <p class="header-text">{{ $t('topbar.organizationChart') }}</p>
                                    <p class="fore-text">{{ $t('topbar.organizationChartDetail') }}</p>
                                </router-link>
                            </div>
                        </HeaderButtonWithContentMobile>
                    </li>
                    <li>
                        <router-link to="/social-responsibility">{{ $t('topbar.socialResponsibility') }}</router-link>
                    </li>
                    <li>
                        <router-link to="/career">{{ $t('topbar.careersAtDogusan') }}</router-link>
                    </li>
                    <li>
                        <router-link to="/contact">{{ $t('topbar.contact') }}</router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import HeaderBranding from './HeaderBranding.vue';
import HeaderButtonWithContentMobile from './HeaderButtonWithContentMobile.vue';

export default {
    name: 'TopBarMobile',
    components: {
        HeaderBranding,
        HeaderButtonWithContentMobile
    },
    data() {
        return {
            isActive: false,
            windowWidth: window.innerWidth,
            activeNavIndex: 0,
        };
    },
    mounted() {
        // Add event listener when the component is mounted
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        // Remove event listener when the component is destroyed
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        toggleMenu() {
            this.isActive = !this.isActive;

            // Toggle body overflow to prevent scrolling
            if (this.isActive) {
                document.body.style.overflow = 'hidden'; // Disable scroll
            } else {
                document.body.style.overflow = ''; // Restore scroll
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        }
    }
}
</script>

<style>
@import '@/components/topbar/TopBarMobile.css';
</style>