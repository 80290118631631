<template>
    <footer>
        <div class="info">
            <div class="navi">
                <div class="companies">
                    <p class="header-text">{{ $t('general.logo') }}</p>
                    <router-link to="/industries/defense"
                        class="footer-btn">{{ $t('general.defenseIndustry') }}</router-link>
                    <router-link to="/industries/marine"
                        class="footer-btn">{{ $t('general.marineIndustry') }}</router-link>
                    <div class="line"></div>
                    <router-link to="/industries/energy/global-sirnak-shell" class="footer-btn">Global Şırnak
                        Shell</router-link>
                    <router-link to="/industries/energy/dogusan-milas-shell" class="footer-btn">Milas Doğusan
                        Shell</router-link>
                    <router-link to="/industries/energy/maviliman-milas-shell" class="footer-btn">Milas Maviliman
                        Shell</router-link>
                    <router-link to="/industries/energy/saltan-petrol-sirnak-opet" class="footer-btn">Saltan Petrol
                        Şırnak Opet</router-link>
                </div>
                <div class="site-map">
                    <p class="header-text">{{ $t('topbar.siteMap') }}</p>
                    <router-link to="/" class="footer-btn">{{ $t('topbar.home') }}</router-link>
                    <router-link to="/social-responsibility" class="footer-btn">{{ $t('topbar.socialResponsibility') }}</router-link>
                    <router-link to="/career" class="footer-btn">{{ $t('topbar.careersAtDogusan') }}</router-link>
                    <router-link to="/contact" class="footer-btn">{{ $t('topbar.contact') }}</router-link>
                    <p class="footer-fore-text">{{ $t('topbar.corporate') }}</p>
                    <router-link to="/about" class="footer-btn">{{ $t('topbar.about') }}</router-link>
                    <router-link to="/founder" class="footer-btn">{{ $t('topbar.founder') }}</router-link>
                    <router-link to="/message-from-manager" class="footer-btn">{{ $t('topbar.fromChairperson') }}</router-link>
                    <router-link to="/organization-scheme" class="footer-btn">{{ $t('topbar.organizationChart') }}</router-link>
                </div>
            </div>
            <div class="contact">
                <p class="header-text">{{ $t('topbar.contact') }}</p>
                <p class="fore-text">Bahçelievler Mah. Mardin Bulvarı <br> No: 324, Merkez/ŞIRNAK - Türkiye</p>
                <p class="fore-text">+90 486 221 1006</p>
                <a href="mailto:info@dgsnas.com">info@dgsnas.com</a>
            </div>
        </div>
        <div class="bottom-content">
            <p>{{ $t('topbar.allRightsReserved') }}</p>
            <img src="@/assets/logo/logo-full.png" alt="Doğusan Logo">
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<style>
@import "@/components/FooterComponent.css";
</style>
