<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/pages/career/bg.jpg">
            <p class="career-header" style="text-shadow: var(--shadow-4);">{{ $t('careerPage.quote') }}</p>
        </div>
        <div class="energy-content">
            <p class="energy-block-header" style="font-size: 2rem;">{{ $t('careerPage.careerTitle') }}</p>
            <p class="energy-block-header">{{ $t('careerPage.recruitmentPolicyTitle') }}</p>
            <p v-html="$t('careerPage.recruitmentPolicyDescription')"></p>
            <p class="energy-block-header energy-block-header-second">{{ $t('careerPage.trainingPolicyTitle') }}</p>
            <p v-html="$t('careerPage.trainingPolicyDescription')"></p>
            <p class="energy-block-header energy-block-header-second">{{ $t('careerPage.compensationPolicyTitle') }}</p>
            <p>{{ $t('careerPage.compensationPolicyDescription') }}</p>
            <p class="energy-block-header energy-block-header-second">{{ $t('careerPage.employeeParticipationTitle') }}
            </p>
            <p v-html="$t('careerPage.employeeParticipationDescription')"></p>
            <p class="energy-block-header energy-block-header-second">{{ $t('careerPage.generalApplicationTitle') }}</p>
            <p>{{ $t('careerPage.generalApplicationDescription') }}</p>
            <br>
            <p>{{ $t('careerPage.cvInstructionsBefore') }} <span><a href="mailto:info@dgsnas.com"
                        style="color: var(--brand-color);">info@dgsnas.com</a></span> {{
                            $t('careerPage.cvInstructionsAfter') }}</p>
            <br><br>
            <LinkButton :title="$t('careerPage.applyNow')" linkTo="mailto:info@dgsnas.com" :isLinkExternal="true" />
            <br><br>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import LinkButton from '@/components/LinkButton.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'CareerPage',
    components: {
        TopBar,
        FooterComponent,
        LinkButton
    }
}
</script>

<style scoped>
@import '@/pages/career/CareerPage.css';
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>