<template>
    <div class="mtopbar-btn-with-content">
        <div @click="toggleMenuContent()" class="elements">
            <p>{{ title }}</p>
            <div class="go-icon"></div>
        </div>
        <div :id="idName" class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderButtonWithContentMobile',
    props: {
        idName: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    methods: {
        toggleMenuContent() {
            const e = document.getElementById(this.idName);
            if (e.classList.contains('active')) {
                e.classList.remove('active');
            } else {
                e.classList.add('active');
            }
        }
    }
}
</script>

<style scoped>
.mtopbar-btn-with-content .elements {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.mtopbar-btn-with-content .content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.mtopbar-btn-with-content .content.active {
    max-height: 400px;
    /* A value greater than the expected content height */
    opacity: 1;
}
</style>
