<template>
    <TopBar alwaysOpen=true />
    <div class="wrapper founder-wrapper">
        <div class="energy-block energy-right-block">
            <div class="energy-block-details">
                <p class="energy-block-header">{{ $t('founderPage.title') }}</p>
                <p v-html="$t('founderPage.description')"></p>
            </div>
            <img src="@/assets/images/ceo.png" class="fonder-img">
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'FounderPage',
    components: {
        TopBar,
        FooterComponent
    }
}
</script>

<style>
@import '@/pages/career/CareerPage.css';
@import "@/pages/industries/energy/GeneralEnergyPage.css";

.founder-wrapper {
    text-align: start;
    margin-top: 150px;
    padding: 0 var(--default-content-inner-padding);
}

.fonder-img {
    border-radius: 0 !important;
    height: 300px !important;
}


@media (max-width: 1100px) {
    .fonder-img {
        height: 700px !important;
    }

    .founder-wrapper {
        margin-bottom: 20px !important;
        margin-top: 100px !important;
    }
}

@media (max-width: 900px) {
    .fonder-img {
        height: 600px !important;
    }
}

@media (max-width: 800px) {
    .fonder-img {
        height: 500px !important;
    }
}

@media (max-width: 500px) {
    .fonder-img {
        height: 400px !important;
    }
}
</style>