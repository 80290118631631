<template>
    <TopBar alwaysOpen=true />
    <div class="wrapper">
        <div class="pdf-container">
            <h1 style="text-align: start; padding: 20px;">{{ $t('general.organizationChart')}}</h1>
            <iframe class="pdf-iframe" :src="pdfUrl"></iframe>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: "HomePage",
    components: {
        TopBar,
        FooterComponent,
    },
    module: {
        rules: [
            {
                test: /\.(pdf)$/,
                use: 'file-loader',
            }
        ]
    },
    data() {
        return {
            pdfUrl: '/organization-chart.pdf#toolbar=0&navpanes=0&scrollbar=0'
        };
    }
};
</script>

<style>
.pdf-container {
    margin-top: 150px;
}

.pdf-iframe {
    width: 100%;
    height: 595px;
    border: none;
    border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .pdf-iframe {
        height: 400px;
    }
}
</style>
