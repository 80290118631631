<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/pages/defense/bg.png" alt="image of defense industrie">
        </div>
        <div class="energy-content">
            <div class="energy-details">
                <h1>{{ $t('defense.title') }}</h1>
                <p>{{ $t('defense.description') }}</p>
            </div>
            <div class="energy-block energy-left-block">
                <div class="defense-block-img-holder">
                    <img class="defense-block-img" src="@/assets/images/pages/defense/iag-logo.webp">
                    <img class="defense-block-img" src="@/assets/images/pages/defense/best-grup-logo.png">
                    <img class="defense-block-img" src="@/assets/images/pages/defense/nurol-logo.png">
                </div>
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('defense.distributorTitle') }}</p>
                    <p>{{ $t('defense.distributorDetail') }}</p>
                </div>
            </div>
            <div class="energy-block energy-right-block">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('defense.sparePartsTitle') }}</p>
                    <p>{{ $t('defense.sparePartsDetail') }}</p>
                </div>
                <img src="@/assets/images/defense.jpeg" style="height: 275px !important;">
            </div>
            <div class="defense-img-block">
                <div class="energy-block-details">
                    <p class="energy-block-header energy-block-header-second">{{ $t('defense.maintenanceRepairTitle') }}
                    </p>
                    <p>{{ $t('defense.maintenanceRepairDetail') }}</p>
                </div>
                <div class="defense-img-block-img-holder" style="margin-top: 30px;">
                    <swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :pagination="{ clickable: true }"
                        :zoom="true" :navigation="true" :autoplay="{ delay: 3000 }" :modules="modules"
                        class="defense-swiper">
                        <swiper-slide>
                            <div class="swiper-zoom-container"><img
                                    src="@/assets/images/pages/defense/container/1.jpg" /></div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-zoom-container"><img
                                    src="@/assets/images/pages/defense/container/2.jpg" /></div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-zoom-container"><img
                                    src="@/assets/images/pages/defense/container/3.jpg" /></div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-zoom-container"><img
                                    src="@/assets/images/pages/defense/container/4.jpg" /></div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-zoom-container"><img
                                    src="@/assets/images/pages/defense/container/5.jpg" /></div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-zoom-container"><img
                                    src="@/assets/images/pages/defense/container/6.jpg" /></div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div class="energy-block energy-left-block">
                <img id="defense-mobile-service-img" src="@/assets/images/pages/defense/mobile-service.jpeg">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('defense.mobileServiceTitle') }}</p>
                    <p>{{ $t('defense.mobileServiceDetail') }}</p>
                    <br>
                    <p>{{ $t('defense.summary') }}</p>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

// Import Swiper Vue.js components and modules
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Zoom, Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

export default {
    name: 'DefenseIndustrie',
    components: {
        TopBar,
        FooterComponent,
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Zoom, Navigation, Pagination, Autoplay],
        };
    },
    metaInfo: {
        title: 'Savunma Sanayi | Doğusan Grup',
        meta: [
            {
                name: 'description',
                content: 'Zırhlandırılmış araçlar için yedek parça, bakım ve onarım çalışmalarımızı keşfedin.'
            },
            {
                name: 'keywords',
                content: 'zırhlı araç, tamir, bakım servis, mobil servis, iag, nurol, best grup, yedek parça, savunma sanayi, güvenlik, doğusan'
            },
            {
                property: 'og:title',
                content: 'Savunma Sanayi | Doğusan Grup'
            },
            {
                property: 'og:description',
                content: 'Zırhlandırılmış araçlar için yedek parça, bakım ve onarım çalışmalarımızı keşfedin.'
            },
            {
                property: 'og:image',
                content: 'https://dgsnas.com/img/logo-full.4d92365c.png'
            },
            {
                property: 'og:url',
                content: 'https://dgsnas.com/industries/defense'
            }
        ]
    }
}
</script>

<style>
@import "@/pages/industries/Defense.css";
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>