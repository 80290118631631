<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/landing-bg/marine.jpeg">
        </div>
        <div class="energy-content">
            <div class="energy-details">
                <h1>{{ $t('marinePage.title') }}</h1>
                <p v-html="$t('marinePage.introduction')"></p>
            </div>
            <div class="energy-block energy-left-block">
                <img src="@/assets/images/pages/marine/iot.jpg">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('marinePage.securitySystemsTitle') }}</p>
                    <p v-html="$t('marinePage.securitySystemsDescription')"></p>
                </div>
            </div>
            <div class="energy-block energy-right-block">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('marinePage.iotSolutionsTitle') }}</p>
                    <p v-html="$t('marinePage.iotSolutionsDescription')"></p>
                </div>
                <img src="@/assets/images/pages/marine/security.jpg">
            </div>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'MarinePage',
    components: {
        TopBar,
        FooterComponent
    },
    metaInfo: {
        title: 'Su Teknolojileri | Doğusan Grup',
        meta: [
            {
                name: 'description',
                content: 'Doğusan Grup olarak, denizcilik sektöründe IoT ve akıllı güvenlik sistemleri ile gemi ekipmanlarının izlenmesi, performans takibi ve arıza öngörüsü sağlayarak güvenlik ve verimliliği artırmayı hedefliyoruz.'
            },
            {
                name: 'keywords',
                content: 'Doğusan Grup, denizcilik sektörü, IoT çözümleri, gemi güvenlik sistemleri, denizcilik teknolojileri, akıllı sensörler, performans takibi, arıza öngörüsü, operasyonel verimlilik, güvenlik çözümleri'
            },
            {
                property: 'og:title',
                content: 'Su Teknolojileri | Doğusan Grup'
            },
            {
                property: 'og:description',
                content: 'Doğusan Grup olarak, denizcilik sektöründe yenilikçi ve güvenilir çözümler sunuyoruz. IoT ve akıllı güvenlik sistemleri ile gemi ekipmanlarının gerçek zamanlı izlenmesi ve performans takibini sağlayarak operasyonel süreçleri optimize ediyoruz.'
            },
            {
                property: 'og:image',
                content: 'https://dgsnas.com/img/logo-full.4d92365c.png'
            },
            {
                property: 'og:url',
                content: 'https://dgsnas.com/industries/marine'
            },
        ]
    }
}
</script>

<style>
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>