<template>
    <div class="blur-effect" :class="{ 'visible': (isServicesPanelOpen || isCorporatePanelOpen) }"></div>
    <div class="mobile-header">
        <TopBarMobile />
    </div>
    <header :class="{ 'default-color': headerIsDefaultColor }">
        <div class="header-content">
            <HeaderBranding :headerIsDefaultColor="headerIsDefaultColor" />
            <nav>
                <ul>
                    <li>
                        <HeaderButtonWithContent :title="$t('topbar.sectors')" :slogan="$t('topbar.sectorsDetail')">
                            <div class="services-content">
                                <div class="button-holder energy">
                                    <p class="header-text">{{ $t('general.energyIndustry') }}</p>
                                    <router-link to="/industries/energy/global-sirnak-shell" class="hover-btn">Global
                                        Şırnak Shell</router-link>
                                    <router-link to="/industries/energy/dogusan-milas-shell" class="hover-btn">Doğusan
                                        Milas Shell</router-link>
                                    <router-link to="/industries/energy/maviliman-milas-shell"
                                        class="hover-btn">Maviliman Milas Shell</router-link>
                                    <router-link to="/industries/energy/saltan-petrol-sirnak-opet"
                                        class="hover-btn">Saltan Petrol Şırnak Opet</router-link>
                                </div>
                                <div class="button-holder other">
                                    <router-link to="/industries/defense" class="btn-with-text">
                                        <div class="header-text-holder defense-text">
                                            <p class="header-text">{{ $t('general.defenseIndustry') }}</p>
                                            <div class="icon"></div>
                                        </div>
                                        <p class="fore-text">{{ $t('general.defenseIndustryDetail') }}</p>
                                    </router-link>
                                    <router-link to="/industries/marine" class="btn-with-text">
                                        <div class="header-text-holder">
                                            <p class="header-text">{{ $t('general.marineIndustry') }}</p>
                                            <div class="icon"></div>
                                        </div>
                                        <p class="fore-text">{{ $t('general.marineIndustryDetail') }}</p>
                                    </router-link>
                                </div>
                            </div>
                        </HeaderButtonWithContent>
                    </li>
                    <li>
                        <HeaderButtonWithContent :title="$t('topbar.corporate')" :slogan="$t('topbar.corporateDetail')">
                            <div class="corporate-content">
                                <router-link to="/about" class="btn-with-text">
                                    <div class="header-text-holder">
                                        <p class="header-text">{{ $t('topbar.about') }}</p>
                                        <div class="icon"></div>
                                    </div>
                                    <p class="fore-text">{{ $t('topbar.aboutDetail') }}</p>
                                </router-link>
                                <router-link to="/founder" class="btn-with-text">
                                    <div class="header-text-holder">
                                        <p class="header-text">{{ $t('topbar.founder') }}</p>
                                        <div class="icon"></div>
                                    </div>
                                    <p class="fore-text">{{ $t('topbar.founderDetail') }}</p>
                                </router-link>
                                <router-link to="/message-from-manager" class="btn-with-text">
                                    <div class="header-text-holder">
                                        <p class="header-text">{{ $t('topbar.fromChairperson') }}</p>
                                        <div class="icon"></div>
                                    </div>
                                    <p class="fore-text">{{ $t('topbar.fromChairpersonDetail') }}</p>
                                </router-link>
                                <router-link to="/organization-scheme" class="btn-with-text">
                                    <div class="header-text-holder">
                                        <p class="header-text">{{ $t('topbar.organizationChart') }}</p>
                                        <div class="icon"></div>
                                    </div>
                                    <p class="fore-text">{{ $t('topbar.organizationChartDetail') }}</p>
                                </router-link>
                            </div>
                        </HeaderButtonWithContent>
                    </li>
                    <li>
                        <router-link to="/social-responsibility"
                            :class="{ 'default-color': headerIsDefaultColor }">{{ $t('topbar.socialResponsibility') }}
                            <div class="go-icon"></div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/career"
                            :class="{ 'default-color': headerIsDefaultColor }">{{ $t('topbar.careersAtDogusan') }}
                            <div class="go-icon"></div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/contact"
                            :class="{ 'default-color': headerIsDefaultColor }">{{ $t('topbar.contact') }}
                            <div class="go-icon"></div>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
import HeaderBranding from './HeaderBranding.vue';
import HeaderButtonWithContent from './HeaderButtonWithContent.vue';
import TopBarMobile from './TopBarMobile.vue';
import { ref, provide } from 'vue';

export default {
    name: 'TopBar',
    components: {
        HeaderButtonWithContent,
        TopBarMobile,
        HeaderBranding
    },
    props: {
        alwaysOpen: {
            type: Boolean
        },
    },
    setup() {
        const isServicesPanelOpen = ref(false);
        const isCorporatePanelOpen = ref(false);

        const scrollPosition = ref(0);
        const headerBeginingSpacing = ref(60);
        const headerIsDefaultColor = ref(false);

        // Provide these reactive references for descendant components
        provide('isServicesPanelOpen', isServicesPanelOpen);
        provide('isCorporatePanelOpen', isCorporatePanelOpen);

        provide('scrollPosition', scrollPosition);
        provide('headerBeginingSpacing', headerBeginingSpacing);
        provide('headerIsDefaultColor', headerIsDefaultColor);

        return {
            isServicesPanelOpen,
            isCorporatePanelOpen,
            scrollPosition,
            headerBeginingSpacing,
            headerIsDefaultColor,
        };
    },
    mounted() {
        if (this.alwaysOpen) {
            this.scrollPosition = this.headerBeginingSpacing + 1;
            this.headerBeginingSpacing = 0;
            this.headerIsDefaultColor = true;
            return;
        }
        document.documentElement.style.setProperty("--begining-header-spacing", this.headerBeginingSpacing + "px");
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    computed: {
        headerLogoTextStyle() {
            return this.headerIsDefaultColor
                ? { color: "var(--brand-color)", textShadow: "none" }
                : { color: "white", textShadow: "var(--text-shadow-2)" };
        },
    },
    methods: {
        switchLanguage(lang) {
            this.$i18n.locale = lang;
        },

        handleScroll() {
            if (this.alwaysOpen) {
                this.scrollPosition = this.headerBeginingSpacing + 1;
                return;
            }
            this.scrollPosition = window.scrollY || window.pageYOffset;
            const root = document.documentElement;

            if (this.scrollPosition > this.headerBeginingSpacing) {
                root.style.setProperty("--begining-header-spacing", 0 + "px");
                this.headerIsDefaultColor = true;
            } else {
                root.style.setProperty(
                    "--begining-header-spacing",
                    this.headerBeginingSpacing - this.scrollPosition + "px"
                );
                if (!this.isServicesPanelOpen && !this.isCorporatePanelOpen) {
                    this.headerIsDefaultColor = false;
                }
            }
        },
    },
};
</script>

<style>
@import '@/components/topbar/TopBar.css';
</style>
