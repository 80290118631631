<template>
    <div class="background">
        <div class="landing-bg-holder">
            <swiper :slidesPerView="1" :spaceBetween="0" :loop="true" :pagination="{ clickable: false }"
                :navigation="false" :speed="1000" class="my-swiper" ref="sliderRef" @swiper="setSwiperInstance">
                <swiper-slide v-for="(slide, index) in landingBgDataArray" :key="index" :id="'swiper-slide-' + index"
                    :class="{ 'bg-size-grow': currentSlide === index, 'bg-size-shrink': currentSlide !== index }">
                    <img :src="require(`@/assets/images/landing-bg/${slide.image}`)" :alt="slide.title" />
                </swiper-slide>
            </swiper>
        </div>
        <div class="content-holder">
            <div id="content">
                <p class="title">{{ currentSlideData.title }}</p>
                <p id="content-detail" class="detail">{{ currentSlideData.detail }}</p>
                <LinkButton :title="$t('general.details').toUpperCase()" :linkTo="currentSlideData.link"
                    :isLinkScrolling="currentSlideData.isLinkSegment" />
            </div>
            <div id="bottom-panel">
                <div class="explore-more" @click="scrollToExplore">
                    <p>{{ $t('general.explore') }}</p>
                    <div class="scroll-down">
                        <span class="arrow"></span>
                    </div>
                </div>
                <div class="btns-control-holder">
                    <div class="btn-control" @click="prevSlide" ref="prevButton">
                        <div class="icon left-icon"></div>
                    </div>
                    <div class="btn-control" @click="nextSlide" ref="nextButton">
                        <div class="icon right-icon"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import LinkButton from '@/components/LinkButton.vue';
import { ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

export default {
    name: 'BackgroundSlider',
    components: {
        LinkButton,
        Swiper,
        SwiperSlide,
    },
    setup() {
        const sliderRef = ref(null);
        const swiperInstance = ref(null);

        // Capture the Swiper instance when it's ready
        const setSwiperInstance = (swiper) => {
            swiperInstance.value = swiper;
        };

        return {
            sliderRef,
            swiperInstance,
            setSwiperInstance,
        };
    },
    mounted() {
        this.setBottomPanelMargin();
        // Set currentSlide to 0 after a short delay to trigger the animation
        setTimeout(() => {
            this.currentSlide = 0;
            this.triggerSizeAnim(0); // Optional: if you want to manually trigger any additional animations
        }, 100); // Delay can be adjusted as needed
        this.startAutoPlay();
    },
    beforeUnmount() {
        // Clear interval when component is destroyed
        this.stopAutoPlay();
    },
    methods: {
        scrollToExplore() {
            window.scrollTo({
                top: window.innerHeight,
                behavior: 'smooth'
            });
        },
        setBottomPanelMargin() {
            // Create a temporary element to get 100vh
            const vhElement = document.createElement("div");
            vhElement.style.height = "100vh";
            vhElement.style.position = "absolute";
            vhElement.style.visibility = "hidden";
            document.body.appendChild(vhElement);

            const vhHeight = vhElement.offsetHeight;

            // Calculate 100dvh by resizing a temporary element
            const dvhElement = document.createElement("div");
            dvhElement.style.height = "100dvh";
            dvhElement.style.position = "absolute";
            dvhElement.style.visibility = "hidden";
            document.body.appendChild(dvhElement);

            const dvhHeight = dvhElement.offsetHeight;

            // Remove the temporary elements
            document.body.removeChild(vhElement);
            document.body.removeChild(dvhElement);

            const result = vhHeight - dvhHeight
            document.documentElement.style.setProperty("--mobile-topbar-height", `${result}px`);
        },
        startAutoPlay() {
            this.interval = setInterval(() => {
                this.nextSlide();
            }, 5000); // Automatically change slide every 5 seconds
        },
        stopAutoPlay() {
            if (this.interval) {
                clearInterval(this.interval); // Clears the timer
                this.interval = null; // Reset the interval reference
            }
        },
        triggerSizeAnim(targetSlideIndex) {
            const currentSlide = document.getElementById('swiper-slide-' + targetSlideIndex);
            currentSlide.classList.add('bg-size-grow');
        },
        shrinkSizeAnim(targetSlideIndex) {
            const currentSlide = document.getElementById('swiper-slide-' + targetSlideIndex);
            currentSlide.classList.add('bg-size-shrink');
        },
        triggerMoveAnim(direction) {
            const content = document.getElementById('content');
            content.classList.add('content-anim-' + direction);
            setTimeout(() => {
                content.classList.remove('content-anim-' + direction);
                this.isTransitioning = false;
            }, 1500);
        },
        nextSlide() {
            if (this.isTransitioning) return;
            this.isTransitioning = true;

            let targetSlideIndex = 0;
            if (this.currentSlide < this.landingBgDataArray.length - 1) {
                targetSlideIndex = this.currentSlide + 1;
            } else {
                targetSlideIndex = 0;
            }

            this.stopAutoPlay();
            this.triggerMoveAnim('next');
            this.shrinkSizeAnim(this.currentSlide);
            this.swiperInstance.slideNext();

            setTimeout(() => {
                this.currentSlide = targetSlideIndex;
                this.triggerSizeAnim(targetSlideIndex);
                this.startAutoPlay();
            }, 500);
        },
        prevSlide() {
            if (this.isTransitioning) return;
            this.isTransitioning = true;

            let targetSlideIndex = 0;
            if (this.currentSlide > 0) {
                targetSlideIndex = this.currentSlide - 1;
            } else {
                targetSlideIndex = this.landingBgDataArray.length - 1;
            }

            this.stopAutoPlay();
            this.triggerMoveAnim('prev');
            this.shrinkSizeAnim(this.currentSlide);
            this.swiperInstance.slidePrev();

            setTimeout(() => {
                this.currentSlide = targetSlideIndex;
                this.triggerSizeAnim(targetSlideIndex);
                this.startAutoPlay();
            }, 500);
        },
    },
    computed: {
        currentSlideData() {
            const realIndex = this.currentSlide % this.landingBgDataArray.length;
            return this.landingBgDataArray[realIndex];
        }
    },
    data() {
        return {
            intervalId: null,
            currentSlide: null, // Start with null
            isAnimating: true,
            isTransitioning: false,
            landingBgDataArray: [
                {
                    title: this.$toLocaleUppercase(this.$t('general.marineIndustry')),
                    detail: this.$t('general.marineIndustryDetail'),
                    link: "/industries/marine",
                    image: "marine.jpeg",
                    isLinkSegment: false
                },
                {
                    title: this.$toLocaleUppercase(this.$t('general.defenseIndustry')),
                    detail: this.$t('general.defenseIndustryDetail'),
                    link: "/industries/defense",
                    image: "defense.jpeg",
                    isLinkSegment: false
                },
                {
                    title: this.$toLocaleUppercase(this.$t('general.energyIndustry')),
                    detail: this.$t('general.energyIndustryDetail'),
                    link: "energy-segment",
                    image: "energy.jpeg",
                    isLinkSegment: true
                }
            ]
        };
    }
};
</script>

<style>
@import '@/components/home_page/BackgroundSlider.css';
</style>
