// Import necessary modules and components
import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import { createRouter, createWebHistory } from 'vue-router';
import VueGtag from 'vue-gtag';

// Import pages
import Home from './pages/HomePage.vue';
import GlobalSirnakShell from './pages/industries/energy/GlobalSirnakShell.vue';
import DogusanMilasShell from './pages/industries/energy/DogusanMilasShell.vue';
import MavilimanMilasShell from './pages/industries/energy/MavilimanMilasShell.vue';
import SaltanPetrol from './pages/industries/energy/SaltanPetrol.vue';
import Defense from './pages/industries/Defense.vue';
import Marine from './pages/industries/Marine.vue';
import CareerPage from './pages/career/CareerPage.vue';
import About from './pages/corparate/AboutPage.vue';
import FounderPage from './pages/corparate/FounderPage.vue';
import MessageFromManager from './pages/corparate/MessageFromManager.vue';
import OrganizationScheme from './pages/corparate/OrganizationScheme.vue';
import SocialResponsibilityPage from './pages/social-responsibility/SocialResponsibilityPage.vue';
import ContactPage from './pages/contact/ContactPage.vue';

// Import translation files
import trHome from './locales/tr/homepage.json';
import trGeneral from './locales/tr/general.json';
import trTopbar from './locales/tr/topbar.json';
import trAbout from './locales/tr/about.json';
import trFounder from './locales/tr/founder.json';
import trChairpersonMessage from './locales/tr/chairpersonMessage.json';
import trContact from './locales/tr/contact.json';
import trCareerPage from './locales/tr/careerPage.json';
import trSocialResponsibilityPage from './locales/tr/socialResponsibilityPage.json';
import trDefense from './locales/tr/defense.json';
import trMarine from './locales/tr/marine.json';
import trDogusanMilasShell from './locales/tr/dogusanMilasShell.json';
import trGlobalSirnakShell from './locales/tr/globalSirnakShell.json';
import trMavilimanMilasShell from './locales/tr/mavilimanMilasShell.json';
import trSaltanPetrolOpet from './locales/tr/saltanPetrolOpet.json';

import enHome from './locales/en/homepage.json';
import enGeneral from './locales/en/general.json';
import enTopbar from './locales/en/topbar.json';
import enAbout from './locales/en/about.json';
import enFounder from './locales/en/founder.json';
import enChairpersonMessage from './locales/en/chairpersonMessage.json';
import enContact from './locales/en/contact.json';
import enCareerPage from './locales/en/careerPage.json';
import enSocialResponsibilityPage from './locales/en/socialResponsibilityPage.json';
import enDefense from './locales/en/defense.json';
import enMarine from './locales/en/marine.json';
import enDogusanMilasShell from './locales/en/dogusanMilasShell.json';
import enGlobalSirnakShell from './locales/en/globalSirnakShell.json';
import enMavilimanMilasShell from './locales/en/mavilimanMilasShell.json';
import enSaltanPetrolOpet from './locales/en/saltanPetrolOpet.json';

import deHome from './locales/de/homepage.json';
import deGeneral from './locales/de/general.json';
import deTopbar from './locales/de/topbar.json';
import deAbout from './locales/de/about.json';
import deFounder from './locales/de/founder.json';
import deChairpersonMessage from './locales/de/chairpersonMessage.json';
import deContact from './locales/de/contact.json';
import deCareerPage from './locales/de/careerPage.json';
import deSocialResponsibilityPage from './locales/de/socialResponsibilityPage.json';
import deDefense from './locales/de/defense.json';
import deMarine from './locales/de/marine.json';
import deDogusanMilasShell from './locales/de/dogusanMilasShell.json';
import deGlobalSirnakShell from './locales/de/globalSirnakShell.json';
import deMavilimanMilasShell from './locales/de/mavilimanMilasShell.json';
import deSaltanPetrolOpet from './locales/de/saltanPetrolOpet.json';

// Set up i18n messages
const messages = {
  de: {
    home: deHome,
    general: deGeneral,
    topbar: deTopbar,
    aboutPage: deAbout,
    founderPage: deFounder,
    chairpersonMessage: deChairpersonMessage,
    contact: deContact,
    careerPage: deCareerPage,
    socialResponsibilityPage: deSocialResponsibilityPage,
    defense: deDefense,
    marinePage: deMarine,
    dogusanMilasShell: deDogusanMilasShell,
    globalSirnakShell: deGlobalSirnakShell,
    mavilimanMilasShell: deMavilimanMilasShell,
    saltanPetrolOpet: deSaltanPetrolOpet,
  },
  en: {
    home: enHome,
    general: enGeneral,
    topbar: enTopbar,
    aboutPage: enAbout,
    founderPage: enFounder,
    chairpersonMessage: enChairpersonMessage,
    contact: enContact,
    careerPage: enCareerPage,
    socialResponsibilityPage: enSocialResponsibilityPage,
    defense: enDefense,
    marinePage: enMarine,
    dogusanMilasShell: enDogusanMilasShell,
    globalSirnakShell: enGlobalSirnakShell,
    mavilimanMilasShell: enMavilimanMilasShell,
    saltanPetrolOpet: enSaltanPetrolOpet,
  },
  tr: {
    home: trHome,
    general: trGeneral,
    topbar: trTopbar,
    aboutPage: trAbout,
    founderPage: trFounder,
    chairpersonMessage: trChairpersonMessage,
    contact: trContact,
    careerPage: trCareerPage,
    socialResponsibilityPage: trSocialResponsibilityPage,
    defense: trDefense,
    marinePage: trMarine,
    dogusanMilasShell: trDogusanMilasShell,
    globalSirnakShell: trGlobalSirnakShell,
    mavilimanMilasShell: trMavilimanMilasShell,
    saltanPetrolOpet: trSaltanPetrolOpet,
  }
};

// Define routes
const routes = [
  { path: '/', component: Home },
  { path: '/industries/energy/global-sirnak-shell', component: GlobalSirnakShell },
  { path: '/industries/energy/dogusan-milas-shell', component: DogusanMilasShell },
  { path: '/industries/energy/maviliman-milas-shell', component: MavilimanMilasShell },
  { path: '/industries/energy/saltan-petrol-sirnak-opet', component: SaltanPetrol },
  { path: '/industries/defense', component: Defense },
  { path: '/industries/marine', component: Marine },
  { path: '/career', component: CareerPage },
  { path: '/about', component: About },
  { path: '/founder', component: FounderPage },
  { path: '/message-from-manager', component: MessageFromManager },
  { path: '/organization-scheme', component: OrganizationScheme },
  { path: '/social-responsibility', component: SocialResponsibilityPage },
  { path: '/contact', component: ContactPage },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(), // Use HTML5 history mode for clean URLs
  routes,
  scrollBehavior() {
    document.body.style.overflow = ''; // Restore scroll
    // Scroll to the top on route change
    return { top: 0 };
  },
});

// Check localStorage for saved language or use device's preferred language
const savedLanguage = localStorage.getItem('userLanguage');
const userLanguage = savedLanguage || navigator.language.slice(0, 2);
const availableLanguages = Object.keys(messages);

// Set the initial locale based on saved preference or user's language
const initialLocale = availableLanguages.includes(userLanguage) ? userLanguage : 'tr';

// Create the i18n instance
const i18n = createI18n({
  locale: initialLocale, // Set to saved or detected language
  fallbackLocale: 'en', // Fallback language if translations are missing
  legacy: false,
  messages,
});

// Utility function to convert text to uppercase based on the current locale
function toLocaleUppercase(text) {
  const currentLocale = i18n.global.locale;
  return text.toLocaleUpperCase(currentLocale.value);
}

// Create and mount the Vue app
const app = createApp(App);

// Set Google Analytics
app.use(VueGtag, {
  config: { id: 'G-YG54NNEXR2' }
}, router);
app.use(i18n);
app.use(router);
app.config.globalProperties.$toLocaleUppercase = toLocaleUppercase; // Register it globally

// Vue app mounted olduğunda render event'i tetikle
app.mount('#app').$nextTick(() => {
  document.dispatchEvent(new Event('render-event'));
});

export { toLocaleUppercase }; // Optional: Export if you want to import directly
