<template>
    <TopBar />
    <div class="wrapper">
        <div class="energy-background">
            <img src="@/assets/images/energy-container/dogusan-petrol.jpeg" alt="image of global petrol">
        </div>
        <div class="energy-content">
            <div class="energy-details">
                <h1>{{ $t('dogusanMilasShell.title') }}</h1>
                <p>{{ $t('dogusanMilasShell.description') }}</p>
            </div>
            <div class="energy-block energy-left-block">
                <img src="@/assets/images/pages/energy/DogusanMilasShell/market.jpeg">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('dogusanMilasShell.selectMarketTitle') }}</p>
                    <p>{{ $t('dogusanMilasShell.selectMarketDescription') }}</p>
                    <ul>
                        <br>
                        <li><span><b>{{ $t('dogusanMilasShell.deli2goProductsTitle') }}:</b></span> {{ $t('dogusanMilasShell.deli2goProductsDescription') }}</li>
                        <br>
                        <li><span><b>{{ $t('dogusanMilasShell.coffeeTitle') }}:</b></span> {{ $t('dogusanMilasShell.coffeeDescription') }}</li>
                        <br>
                        <li><span><b>{{ $t('dogusanMilasShell.productRangeTitle') }}:</b></span> {{ $t('dogusanMilasShell.productRangeDescription') }}</li>
                    </ul>
                </div>
            </div>
            <div class="energy-block energy-right-block">
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('dogusanMilasShell.deli2goCafeTitle') }}</p>
                    <p>{{ $t('dogusanMilasShell.deli2goCafeDescription') }}</p>
                    <p class="energy-block-header energy-block-header-second">{{ $t('dogusanMilasShell.safetyStandardsTitle') }}</p>
                    <p>{{ $t('dogusanMilasShell.safetyStandardsDescription') }}</p>
                </div>
                <img src="@/assets/images/pages/energy/DogusanMilasShell/cafe-alani.jpeg">
            </div>
            <div class="energy-block-details">
                <p class="energy-block-header energy-block-header-second">{{ $t('dogusanMilasShell.tirePressureTitle') }}</p>
                <p>{{ $t('dogusanMilasShell.tirePressureDescription') }}</p>
            </div>
            <div class="energy-block-details">
                <p class="energy-block-header energy-block-header-second">{{ $t('dogusanMilasShell.environmentalAwarenessTitle') }}</p>
                <p>{{ $t('dogusanMilasShell.environmentalAwarenessDescription') }}</p>
            </div>
            <div class="energy-block-details">
                <p class="energy-block-header energy-block-header-second">{{ $t('dogusanMilasShell.futureGoalsTitle') }}</p>
                <p>{{ $t('dogusanMilasShell.futureGoalsDescription') }}</p>
            </div>
            <div class="energy-block energy-left-block">
                <iframe class="map-iframe"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12688.160626150477!2d27.717284133892196!3d37.34155799290548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14befa59bffffffd%3A0x874f3240d17cb391!2sShell!5e0!3m2!1str!2str!4v1730662094256!5m2!1str!2str"
                    style="border:0;" allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                </iframe>
                <div class="energy-block-details">
                    <p class="energy-block-header">{{ $t('dogusanMilasShell.locationTitle') }}</p>
                    <p>{{ $t('dogusanMilasShell.locationDescription') }}</p>
                    <p class="energy-block-header energy-block-header-second">{{ $t('dogusanMilasShell.contactInfoTitle') }}</p>
                    <p>{{ $t('dogusanMilasShell.address') }}</p>
                    <br>
                    <div class="energy-icon-holder">
                        <div class="phone-icon"></div>
                        <p>0252 512 1505</p>
                    </div>
                    <div class="energy-icon-holder">
                        <div class="email-icon"></div>
                        <p>6205milasgirisi@stasbayi.com</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
    name: 'DogusanMilasShell',
    components: {
        TopBar,
        FooterComponent
    },
}
</script>

<style>
@import "@/pages/industries/energy/GeneralEnergyPage.css";
</style>
